<template>
    <div class="row">
      <div class="col-md-12">
        <div v-show="show" class="alert alert-danger-custom alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="card">
          <div class="card-body">
            <form ref="createDateWiseForm" action="">
              <div class="form-row">
                <div class="col-md-4">
                  <a-range-picker name="date_range" @change="onDateRangeChange" />
                </div>
                <div class="col-md-4">
                  <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div v-if="products.length > 0">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="delivery_date" class="col-form-label col-form-label-sm">Delivery On Before <span class="custom-required">*</span></label>
                    <datepicker v-model="purchase_request.delivery_date" v-validate="'required'" id="delivery_date" name="delivery_date" :format="customDeliveryDate" :disabled-dates="disabledDates" placeholder="Delivery Date" ></datepicker>
                    <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('delivery_date')">Delivery date required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <form action="">
                <table class="table table-hover table-bordered">
                  <thead>
                  <tr>
                    <th :class="{change_color: scrollPosition > 150}">Name</th>
                    <th :class="{change_color: scrollPosition > 150}">Unit</th>
                    <th :class="{change_color: scrollPosition > 150}">W. Current Qty</th>
                    <th :class="{change_color: scrollPosition > 150}">Requested Qty</th>
                    <th :class="{change_color: scrollPosition > 150}">Unit Price</th>
                    <th :class="{change_color: scrollPosition > 150}">Sub Total</th>
                    <th :class="{change_color: scrollPosition > 150}">Total</th>
                    <th :class="{change_color: scrollPosition > 150}">Remarks</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(product, index) in products" :key="index">
                    <td>{{ product.name }}</td>
                    <td>{{ product.unit }}</td>
                    <td>{{ product.available_quantity }}</td>
                    <td>
                      <input class="form-control form-control-sm"  :id="'qty_'+product.id" v-model="products[index].last_month_total_accepted_amount" v-validate="{ max: 6, regex: /^[0-9]*$/ }" :name="'quantity' + index">
                    </td>
                    <td>
                      <input class="form-control form-control-sm" :id="'unitPrice_'+product.id" v-model="products[index].unit_price" v-validate="{ max: 6, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/ }" :name="'unit_price' + index">
                    </td>
                    <td>
                      <input readonly disabled class="form-control form-control-sm" :id="'sub_total_'+product.id" v-model="subTotalRow[index]" v-validate="{ max: 7, regex: /^[0-9]*$/  }" :name="'sub_total' + index">
                    </td>
                    <td>
                      <input readonly disabled  class="form-control form-control-sm" :id="'total_'+product.id" v-model="totalRow[index]" v-validate="{ max: 7, regex: /^[0-9]*$/  }" :name="'total' + index">
                    </td>
                    <td>
                      <input class="form-control form-control-sm" :id="'remarks_'+product.id" v-model="products[index].remarks" :name="'remarks' + index">
                    </td>
                  </tr>
                  <!--  Total, Others, shipping-->
                  <tr>
                    <th style="text-align: right" colspan="6">SUBTOTAL</th>
                    <td>
                      <input class="form-control form-control-sm" v-model="subtotal" v-validate="'required'" name="grandSubtotal"  id="grandSubtotal" readonly>
                    </td>
                  </tr>
                  <tr>
                    <th style="text-align: right" colspan="6">OTHER</th>
                    <td>
                      <input class="form-control form-control-sm" v-model="purchase_request.others" v-validate="{ required: true, max: 6, regex: /^[0-9]*(?:\.[0-9]{0,2})?$/  }" name="other"  id="other" value="0">
                    </td>
                  </tr>
                  <tr>
                    <th style="text-align: right" colspan="6">TOTAL</th>
                    <td>
                      <input class="form-control form-control-sm" v-model="grandTotal" v-validate="'required'" name="grandTotal"  id="grandTotal" readonly>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <a-skeleton active :loading="loading"></a-skeleton>
                <a-button class="btn btn-success waves-effect waves-light mr-2" :disabled="grandTotal == 0 ? true : false" :loading="savePurchaseRequest"  @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Submit Purchase Request</a-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'create',
  components: { Datepicker },
  data() {
    return {
      products: [],
      productIds: [],
      purchase_request: {
        delivery_date: '',
        others: 0,
      },
      purchase_request_items: [],
      validation_errors: {},
      loader: false,
      loading: false,
      btnLoading: false,
      savePurchaseRequest: false,
      disabledDates: {
        to: new Date(Date.now()),
      },
      search_data: {
        date_range: '',
      },
      show: false,
      scrollPosition: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  computed: {
    subTotalRow() {
      return this.products.map((product) => {
        return (parseFloat(product.last_month_total_accepted_amount || 0) * parseFloat(product.unit_price || 0) || 0)
      })
    },
    totalRow() {
      return this.products.map((product) => {
        var subTotal = (parseFloat(product.last_month_total_accepted_amount || 0) * parseFloat(product.unit_price || 0)) || 0
        return (subTotal) || 0
      })
    },
    subtotal() {
      let total = 0
      const totalRow = this.totalRow || []
      total = totalRow.reduce((subTotal, total) => total + subTotal || 0, 0)
      return parseFloat(total).toFixed(2) || 0
    },
    grandTotal() {
      let total = 0
      const subtotal = this.subtotal || 0
      const others = this.purchase_request.others
      total = parseFloat(subtotal) + parseFloat(others)
      return parseFloat(total).toFixed(2) || 0
    },
  },
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      this.btnLoading = true
      apiClient.post('api/purchase-request/codes/search', this.search_data)
        .then(response => {
          this.btnLoading = false
          const data = response.data
          this.products = data.products
          this.productIds = data.productIds
        })
        .catch(error => {
          this.btnLoading = false
          this.show = true
          this.validation_errors = error.response.data.errors
          this.hide()
        })
    },
    customDeliveryDate(date) {
      this.purchase_request.delivery_date = moment(date).format('YYYY-MM-DD')
      return moment(date).format('YYYY-MM-DD')
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const orderArray = []
          this.productIds.forEach(id => {
            const qtyDiv = '#qty_' + id
            const quantity = $(qtyDiv).val()

            const unitPriceDiv = '#unitPrice_' + id
            const unitPrice = $(unitPriceDiv).val()

            const subTotalDiv = '#sub_total_' + id
            const subTotal = $(subTotalDiv).val()

            const totalDiv = '#total_' + id
            const total = $(totalDiv).val()

            const remarksDiv = '#remarks_' + id
            const remarks = $(remarksDiv).val()

            if (quantity && unitPrice) {
              const order = {
                product_id: id,
                quantity: quantity,
                unit_price: unitPrice,
                sub_total: subTotal,
                total: total,
                remarks: remarks,
              }
              orderArray.push(order)
            }
          })

          if (orderArray.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.savePurchaseRequest = true
          const formData = new FormData()
          formData.append('purchase_request_items', JSON.stringify(orderArray))
          formData.append('delivery_date', this.purchase_request.delivery_date)
          formData.append('subtotal', this.subtotal)
          formData.append('others', this.purchase_request.others)
          formData.append('grand_total', this.grandTotal)
          formData.append('between_date', this.search_data.date_range)
          apiClient.post('api/purchase-requests', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.savePurchaseRequest = false
                this.$router.push({ name: 'purchaseRequest' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
                this.savePurchaseRequest = false
              }
            }).catch(error => {
              this.loader = false
              this.savePurchaseRequest = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>

<style scoped>
.alert-danger-custom{
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.change_color {
  background-color: #4B7CF3;
  color: #ffffff;
}
</style>
